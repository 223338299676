.sticker {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 125px;
  height: 250px;
  padding: 8px;
}

.sticker .MuiTypography-root.MuiTypography-subtitle1 {
  font-size: 8px;
  line-height: 1.2;
}

.sticker .slogan {
  border-color: rgba(0, 0, 0, .6);
  margin-top: 16px;
  padding: 8px;
}

.sticker .items {
  min-height: 40px;
  width: 100%;
}

.sticker .MuiDivider-root.MuiDivider-fullWidth {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  border-color: rgba(0, 0, 0, .6);
}

.sticker .logo {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, .6);
}