.App {
  background-color: #f0f2f5;
  height: calc(100vh - calc(100vh - 100%));
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

html,
body,
#root {
  height: 100%;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
